import { useStaticQuery, graphql } from "gatsby"

export const useLocalFile = filename => {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile {
        nodes {
          name
          absolutePath
          relativePath
          childImageSharp {
            gatsbyImageData
          }
          id
          publicURL
        }
      }
    }
  `)

  const data =
    allFile?.nodes
      ?.map(node => {
        return {
          ...node,
          localFile: {
            childImageSharp: {
              gatsbyImageData: node.childImageSharp?.gatsbyImageData,
            },
          },
        }
      })
      .filter(file => file.name === filename)?.[0] || null

  return data
}
