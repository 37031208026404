import { Button } from "./button"
import { Input } from "./input"
import { Select } from "./select"

const CustomComponents = {
  Button,
  Input,
  Select,
}
export default CustomComponents
