export const Select = {
  baseStyle: {
    fontSize: ["xs", "sm", "md"],
  },
  variants: {
    brand: {
      bg: "dark.50",
    },
  },
  sizes: {},
  defaultProps: {
    size: "lg",
  },
}
