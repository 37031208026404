import { gsap, SplitText, CustomEase, ScrollTrigger } from "wildGsap"

function animateTitles() {
  if (!ScrollTrigger || !SplitText) return

  document.fonts.ready.then(function () {
    const titles = Array.from(
      document.querySelectorAll("h1:not(.home-hero), .jumbo, .jumbo-custom")
    )
    titles.forEach(title => {
      const animateSplit = new SplitText(title, {
        type: "lines, words",
        linesClass: "line-animate",
      })
      const containerSplit = new SplitText(title, {
        type: "lines",
        linesClass: "split-lines",
      })
      gsap.set(title, { autoAlpha: 1 })
      gsap.set(containerSplit.lines, { overflow: "hidden" })
      gsap.set(animateSplit.lines, {
        rotate: 15,
        transformOrigin: "0% 50%",
        opacity: 0,
      })
      gsap.fromTo(
        animateSplit.lines,
        { yPercent: 100, opacity: 0 },
        {
          yPercent: 0,
          duration: 1.8,
          opacity: 1,
          stagger: 0.08,
          ease: CustomEase.create(
            "custom",
            "M0,0 C0.126,0.382 0.064,0.726 0.338,0.924 0.468,1.018 0.886,1 1,1 "
          ),
          rotate: 0,
          // onComplete: () => {
          //   containerSplit.revert()
          //   animateSplit.revert()
          // },
          scrollTrigger: {
            trigger: title,
            start: "top 90%",
            once: true,
          },
        }
      )
    })
  })
}

export default animateTitles
