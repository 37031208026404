/*** DEPENDENCIES ***/
import React, { useEffect, useLayoutEffect, useState } from "react"
import SmoothWrapper from "wildComponents/SmoothWrapper"
import saveFirstUrl from "gatsby-theme-wild-child/src/utils/getParams"
import { gsap } from "wildGsap"
import { useThemeOptions } from "wildHooks/useThemeOptions"
import { showBreakpoints } from "gatsby-theme-wild-child/src/utils/showBreakpoints"
import animateTitles from "../gsap/animateTitles"
import "../assets/MyFontsWebfontsKit.css"
import HubspotScripts from "./HubspotScripts"

/*** COMPONENTS ***/
import Nav from "./nav/Nav"
import Footer from "./footer/Footer"
import { Box, Portal } from "@chakra-ui/react"

/*** CONTEXTS*/
export const MenuContext = React.createContext()
export const DarkContext = React.createContext()

function Layout({
  children,
  smoothScroll = true,
  withTopBar = true,
  isHomePage = false,
  masterTimeline,
  animating,
  index,
  title = "test",
  darkTopBar = false,
  ...props
}) {
  /*** STATE ***/
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isDarkBackground, setIsDarkBackground] = useState(false)
  const { shouldShowBreakpoints } = useThemeOptions()

  useEffect(() => {
    showBreakpoints(shouldShowBreakpoints)
  }, [shouldShowBreakpoints])

  useLayoutEffect(() => {
    const titles = Array.from(
      document.querySelectorAll("h1:not(.home-hero), .jumbo, .jumbo-custom")
    )
    titles.forEach(title => {
      gsap.set(title, { autoAlpha: 0 })
    })
  }, [])

  useEffect(() => {
    const cookieExpiresIn = 7 /* days */
    saveFirstUrl("campaignUrl", cookieExpiresIn)
    animateTitles()
  }, [])

  return (
    <MenuContext.Provider value={{ isMenuOpen, setIsMenuOpen }}>
      <SmoothWrapper smoothScroll={smoothScroll}>
        <DarkContext.Provider value={{ isDarkBackground, setIsDarkBackground }}>
          <Portal>
            <Nav withTopBar={withTopBar} darkTopBar={darkTopBar} />
          </Portal>
          <Box as="main" {...props}>
            {children}
            <Footer />
          </Box>
          <HubspotScripts />
        </DarkContext.Provider>
      </SmoothWrapper>
    </MenuContext.Provider>
  )
}

export default Layout
