export const colors = {
  "brandYellow": {
    "50": "#fcfbe8",
    "100": "#faf8d1",
    "200": "#f5f1a3",
    "300": "#f0ea75",
    "400": "#ebe247",
    "500": "#e5db1a",
    "600": "#b8af14",
    "700": "#8a840f",
    "800": "#5c580a",
    "900": "#2e2c05",
    "default": "#e5db1a",
    "full": "#e5db1a"
  },
  "brandBlue": {
    "50": "#ecf8f8",
    "100": "#daf1f0",
    "200": "#b4e4e2",
    "300": "#8fd6d3",
    "400": "#62c6c1",
    "500": "#44bbb5",
    "600": "#369691",
    "700": "#29706d",
    "800": "#1b4b49",
    "900": "#0e2524",
    "default": "#62c6c1",
    "full": "#62c6c1"
  },
  "brandConcrete": {
    "50": "#f4f4f1",
    "100": "#e9e8e2",
    "200": "#c9c8ba",
    "300": "#bcbaa9",
    "400": "#a5a48d",
    "500": "#8f8d70",
    "600": "#72715a",
    "700": "#565443",
    "800": "#39382d",
    "900": "#1d1c16",
    "default": "#c9c8ba",
    "full": "#c9c8ba"
  },
  "dark": {
    "50": "#f2f2f2",
    "100": "#e6e6e6",
    "200": "#cccccc",
    "300": "#b3b3b3",
    "400": "#999999",
    "500": "#808080",
    "600": "#666666",
    "700": "#4d4d4d",
    "800": "#333333",
    "900": "#1a1a1a",
    "default": "#0b0b0b",
    "full": "#0b0b0b"
  }
}