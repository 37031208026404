const SITE_CONFIG = {
  siteUrl: "https://www.duqe.ae",
  siteTitle: "DUQE Free Zone",
  siteDescription: "We are DUQE",
  googleTagManagerId: "GTM-N3GBS8K",
  gaTrackingId: null,
  templateExtension: ".js",
  gatsbySourceWordpressOptions: {
    type: {
      __all: {
        limit: process.env.NODE_ENV === `development` ? 50 : null,
      },
      MediaItem: {
        createFileNodes: process.env.NODE_ENV === `development` ? true : false,
      },
    },
    html: {
      imageMaxWidth: 1024,
      gatsbyImageOptions: {
        formats: [`webp`, `auto`],
        placeholder: `blurred`,
        quality: 75,
        breakpoints: [391, 768, 1260, 1660],
        backgroundColor: `transparent`,
      },
    },
  },

  /**** THEME OPTIONS ****/
  cssVarPrefix: "duqe",
  smoothScroll: true,
  createPosts: true,
  createPages: true,
  preconnectUrls: [
    "https://www.googletagmanager.com",
    "https://js.hscollectedforms.net",
    "https://track.hubspot.com",
    "https://forms.hsforms.com",
    "https://forms.hubspot.com",
    "res.cloudinary.com",
    "https://duqe.me",
    "js.hsadspixel.net",
    "js.usemessages.com",
    "js.hs-banner.com",
    "js.hs-analytics.net",
  ],
  webfonts: {
    typekit: {
      id: process.env.TYPEKIT_ID,
    },
  },
}

module.exports = SITE_CONFIG
