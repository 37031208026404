import React from "react"
import { Script } from "gatsby"

function HubspotScripts() {
  return (
    <React.Fragment>
      <Script
        id="hs-script-loader"
        key="gatsby-plugin-hubspot"
        src="https://js.hs-scripts.com/21692856.js"
      />
      <Script
        id="hs-script-track"
        dangerouslySetInnerHTML={{
          __html: `
          var _hsq = (window._hsq = window._hsq || []);
          _hsq.push([
            "setPath",
            window.location.pathname + window.location.search + window.location.hash,
          ])
          if (
            window.doNotTrack ||
            navigator.doNotTrack ||
            navigator.msDoNotTrack ||
            "msTrackingProtectionEnabled" in window.external
          ) {
            if (
              window.doNotTrack == "1" ||
              navigator.doNotTrack == "yes" ||
              navigator.doNotTrack == "1" ||
              navigator.msDoNotTrack == "1" ||
              window.external.msTrackingProtectionEnabled()
            ) {
              _hsq.push(["doNotTrack"])
            }
          }`,
        }}
      />
      <Script
        id="ga-1"
        src="https://www.googletagmanager.com/gtag/js?id=G-PH7F7T1RZN"
      />

      <Script
        id="ga-2"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'G-PH7F7T1RZN');
          `,
        }}
      />
    </React.Fragment>
  )
}

export default HubspotScripts
