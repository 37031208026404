import React from "react"
import { Link as GatsbyLink } from "gatsby"

import TopMenu from "./TopMenu"
import {
  Button,
  HStack,
  IconButton,
  Link,
  useBreakpointValue,
  useTheme,
} from "@chakra-ui/react"
import { BsFillTelephoneFill } from "react-icons/bs"

function TopBar({ darkTopBar }) {
  const shouldDisplaySidebar = useBreakpointValue([false, false, true])
  const isMobile = useBreakpointValue([true, true, false])
  const { variables } = useTheme()
  const { topBarHeight } = variables

  return shouldDisplaySidebar ? (
    <HStack
      as="aside"
      height={topBarHeight}
      position="absolute"
      top="0"
      width="100vw"
      zIndex="sticky"
      justify="flex-end"
    >
      <HStack spacing="6" pr="50px">
        <HStack spacing={2}>
          <Link
            href="tel:+97148716555"
            className={darkTopBar ? "nav-link-dark" : "nav-link"}
            fontWeight="normal"
            _hover={{ textDecoration: "none", color: "brandBlue.default" }}
          >
            {isMobile ? (
              <IconButton
                variant="outline"
                aria-label="Call DUQE"
                bg="transparent"
                color={darkTopBar ? "#0b0b0b" : "white"}
                border="none"
                transformOrigin="50% 50%"
                rounded="full"
                size="md"
                display={["none", "none", "flex"]}
                _hover={{
                  transform: "scale(1.2)",
                  bg: "rgba(255,255,255,0.1)",
                }}
                _focus={{ color: "dark.default" }}
                _active={{ color: "dark.default", transform: "scale(1.1)" }}
                icon={
                  <BsFillTelephoneFill
                    size="20px"
                    color={darkTopBar ? "#0b0b0b" : "white"}
                  />
                }
              />
            ) : (
              "+97148716555"
            )}
          </Link>
        </HStack>
        <TopMenu darkTopBar={darkTopBar} />

        <Link as={GatsbyLink} to="/cost-calculator/">
          <Button variant="secondary">Cost Calculator</Button>
        </Link>
      </HStack>
    </HStack>
  ) : (
    <></>
  )
}

export default TopBar
