import React from "react"
import Logo from "../../assets/logos/Logo"
import Section from "wildComponents/Section"
import { SocialFollows } from "wildComponents/social/SocialFollows"
import { useMenuQuery } from "wildHooks"
import {
  Box,
  Divider,
  Heading,
  Stack,
  Text,
  VStack,
  useTheme,
} from "@chakra-ui/react"
import { Link } from "wildComponents/Link"

function Footer() {
  const footerMenu1 = useMenuQuery("FOOTER_MENU_1")
  const footerMenu2 = useMenuQuery("FOOTER_MENU_2")
  const footerMenu3 = useMenuQuery("FOOTER_MENU_3")
  const { variables } = useTheme()
  const { containerPaddingY, componentSpacing } = variables

  return (
    <Section
      bg="dark.default"
      pt={0}
      px={[0, 0, 16, 20, 40, "200px"]}
      containerStyles={{
        position: "relative",
        py: "unset",
        pt: containerPaddingY,
        pb: componentSpacing,
      }}
    >
      <VStack as="footer">
        <Stack
          w="full"
          direction={["column", "column", "row"]}
          spacing={[8, 8, 36]}
          px={[5, 5, 0]}
          mt={[20, 20, "unset"]}
          mb={12}
        >
          <Box>
            <Logo useContrast={false} width="120px" />
          </Box>
          <Heading as="h2" color="white" className="upper-case">
            Reach for your dreams.
            <br />
            <Text as="span" color="brandYellow.default">
              Create your legacy.
            </Text>
          </Heading>
        </Stack>
        <Stack
          direction={["column", "column", "row"]}
          justify="space-between"
          spacing={[8, 8, 20]}
          w="full"
          px={[5, 5, 0]}
        >
          <VStack align="flex-start">
            <Text color="dark.500" fontWeight="bold">
              Duqe
            </Text>
            <VStack flex="1" align="flex-start" as="nav">
              {footerMenu1.menuItems.nodes.map(menuItem => {
                return (
                  <Link to={menuItem.path} key={`footer-link-${menuItem.id}`}>
                    <Text color="white" className="footerLink">
                      {menuItem.label}
                    </Text>
                  </Link>
                )
              })}
            </VStack>
          </VStack>
          <VStack align="flex-start">
            <Text color="dark.500" fontWeight="bold">
              Business Setup
            </Text>
            <VStack flex="1" align="flex-start" as="nav">
              {footerMenu2.menuItems.nodes.map(menuItem => {
                return (
                  <Link to={menuItem.path} key={`footer-link-${menuItem.id}`}>
                    <Text color="white" className="footerLink">
                      {menuItem.label}
                    </Text>
                  </Link>
                )
              })}
            </VStack>
          </VStack>
          <VStack align="flex-start">
            <Text color="dark.500" fontWeight="bold">
              Info
            </Text>
            <VStack flex="1" align="flex-start" as="nav">
              {footerMenu3.menuItems.nodes.map(menuItem => {
                return (
                  <Link to={menuItem.path} key={`footer-link-${menuItem.id}`}>
                    <Text color="white" className="footerLink">
                      {menuItem.label}
                    </Text>
                  </Link>
                )
              })}
            </VStack>
          </VStack>
          <VStack align="flex-start" flex="1">
            <Text color="dark.500" fontWeight="bold">
              Duqe
            </Text>
            <SocialFollows direction="row" spacing={8} color="dark.400" />
          </VStack>
          <VStack align="flex-start" flex="1">
            <Text color="dark.500" fontWeight="bold">
              Address
            </Text>
            <Link url="https://goo.gl/maps/7c4zhEtNhGK425AG6">
              <Text color="white">
                Quarter Deck
                <br /> QE2, Mina Rashid
                <br /> P.O. Box 554789
                <br /> Dubai, UAE
              </Text>
            </Link>
          </VStack>
        </Stack>
      </VStack>
      <Box w="full">
        <Divider mt={20} mb={8} opacity="0.2" bg="white" />
        <Text color="dark.500" textAlign="center" fontSize={"sm"}>
          Copyright © {new Date().getFullYear()} Duqe. All rights reserved. |
          Website by{" "}
          <a
            href="https://wildcreative.com.au"
            target="_blank"
            rel="noreferrer"
          >
            Wild Creative
          </a>
        </Text>
      </Box>
    </Section>
  )
}

export default Footer
