import React, { useContext, useEffect, useRef, useState } from "react"
import { MenuContext } from "../Layout"
import useContrastingColor from "wildHooks/useContrastingColor"
import { ScrollTrigger } from "wildGsap"

import ToggleIcon from "../../assets/icons/MenuToggleIcon"
import { IoMdClose } from "react-icons/io"
import { Button, Flex, Icon, useTheme } from "@chakra-ui/react"
import SearchToggle from "../search/SearchToggle"

function Hamburger() {
  const { setIsMenuOpen, isMenuOpen } = useContext(MenuContext)
  const container = useRef()
  const toggleMenu = useRef()
  const observer = useRef()
  const [color, setColor] = useState("white")
  const { variables } = useTheme()
  const { sidebarMenuWidth, mobileNavHeight } = variables

  useContrastingColor({ color, setColor }, container, { fill: color })
  useContrastingColor(
    { color, setColor },
    container,
    { fill: color },
    "button[aria-label='Toggle search'] svg path"
  )

  useEffect(() => {
    observer.current = ScrollTrigger.observe({
      target: toggleMenu.current,
      type: "touch,pointer",
      onPress: () => {
        setIsMenuOpen(!isMenuOpen)
      },
    })
    return () => {
      observer.current.kill()
    }
  }, [isMenuOpen, setIsMenuOpen])

  return (
    <Flex
      zIndex="tooltip"
      position="fixed"
      w={["auto", "auto", sidebarMenuWidth]}
      py={[2, 2, 8]}
      px={[2, 2, 0]}
      h={[mobileNavHeight[0], mobileNavHeight[0], "auto"]}
      justify="center"
      align="center"
      direction="column"
      spacing={4}
      top="0"
      left="0"
      ref={container}
    >
      <Button
        className={isMenuOpen ? "active" : ""}
        variant="menuToggle"
        aria-label="Toggle menu"
        ref={toggleMenu}
        id="toggleMenu"
      >
        {!isMenuOpen ? (
          <ToggleIcon color={color} />
        ) : (
          <Icon as={IoMdClose} color="dark.default" />
        )}
      </Button>
      <SearchToggle
        color="white"
        aria-label="Toggle search"
        bg="transparent"
        border="none"
        transformOrigin="50% 50%"
        rounded="full"
        size="md"
        iconProps={{ size: "20px" }}
        display={["none", "none", "flex"]}
        _hover={{ transform: "scale(1.2)", bg: "rgba(255,255,255,0.1)" }}
        _focus={{ color: "dark.default" }}
        _active={{ color: "dark.default", transform: "scale(1.1)" }}
      />
    </Flex>
  )
}

export default Hamburger
