//-----------------------------------------------//
// Place all global CSS for every page here //
//-----------------------------------------------//

import { base } from "./foundations/typography"

const globalCSS = {
  /********* UTILITY *********/
  main: { overflowX: "hidden", maxW: "100vw" },
  body: { overflowX: "hidden", maxW: "100vw" },
  "body.chakra-ui-light *:focus": { outline: "none", boxShadow: "none" },
  "#gatsby-focus-wrapper": {
    outline: "unset !important",
    transform: "unset !important",
  },
  ".stop-scroll": {
    overflow: "hidden",
  },
  ".hidden": {
    display: "none",
  },

  /********* SITE-SPECIFIC *********/
  ".subMenuLink": {
    fontSize: ["24px"],
    fontWeight: "bold",
    textTransform: "unset",
    lineHeight: "32px",
    color: "white",
    _hover: {
      color: "brandYellow.default",
    },
  },
  ".post img": {
    mt: 8,
    rounded: "2xl",
  },
  ul: {
    my: 6,
  },
  "::selection": {
    backgroundColor: "brandBlue.100",
    color: "dark.default",
  },
  ".bg-image.gatsby-image-wrapper": {
    w: "100%",
    h: "100%",
    position: "absolute",
    zIndex: "-2",
    left: "0",
    top: "0",
    objectFit: "cover",
  },
  ".react-tel-input": {
    height: 12,
    gridColumnStart: ["1", "1", "2"],
    gridColumnEnd: "3",
  },
  "#gatsby-focus-wrapper .react-tel-input .form-control": {
    width: "100%",
    height: "100%",
    background: "var(--duqe-colors-dark-50)",
    borderColor: "var(--duqe-colors-gray-200)",
    fontSize: "var(--duqe-fontSizes-md)",
    fontFamily: "HelveticaNowText-Regular",
  },

  "#gatsby-focus-wrapper .react-tel-input .search .search-box": {
    width: "100%",
    margin: "unset",
  },

  "#gatsby-focus-wrapper .react-tel-input .search": {
    padding: "10px",
    background: "var(--duqe-colors-dark-50)",
    fontSize: "var(--duqe-fontSizes-md)",
  },

  "#gatsby-focus-wrapper .react-tel-input .flag-dropdown": {
    borderColor: "var(--duqe-colors-gray-200)",
  },

  "#gatsby-focus-wrapper .react-tel-input .country-list": {
    maxHeight: "300px",
  },

  "#gatsby-focus-wrapper .phone-input": {
    gridColumnStart: [1, 1, 2],
    gridColumnEnd: "3",
  },

  "#gatsby-focus-wrapper .currentQuestion": {
    opacity: "0.05",
  },

  ...base,
}

const globalStyle = {
  global: {
    ...globalCSS,
  },
}

export { globalStyle as styles }
