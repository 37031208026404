import { variables as defaults } from "gatsby-theme-wild-child/src/@chakra-ui/gatsby-plugin/foundations/variables.js"

export const variables = {
  ...defaults,

  // NAV
  navHeight: ["70px", "70px", "85px"],
  siteMarginTop: [0, 0, "125px"],
  sideMenuWidth: "100px",
  sidebarMenuWidth: "100px",
  logoHeight: "100%",
  logoWidth: "100px",
  topBarHeight: "115px",
  mobileNavHeight: ["70px", "70px", 0],

  /*** SECTIONS ***/
  contentMaxWidth: "1360px",
  sectionPaddingY: [0],
  sectionPaddingX: [5, 5, 12, 20, 24, 40, "200px"],
  containerPaddingY: [20, 20, 32, 48, 60],
  containerPaddingX: [0],
  componentSpacing: [4, 8, 8, 8, 8, 10, 12],
  rowSpacing: [8, 8, 12, 12, 16, 20, 20],

  /**** FOOTER ****/
  newsletterOffset: [0, 0, 0 /*"224px"*/],
}
